.container {
  z-index: 99999;

  :global {
    .ant-modal-close,
    .ant-modal-footer {
      display: block;
    }

    .ant-modal-footer {
      margin-top: 32px;
      display: flex;
      justify-content: space-between;
    }
  }
}
