@import 'src/styles/responsive';

.parent {
  pointer-events: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  box-sizing: border-box;
  button {
    margin: 5px 0;
    transition: transform 0.1s;
    @include device(stdSmUp) {
      margin: 10px 0;
    }
  }

  button:hover {
    cursor: pointer;
    transform: scale(0.95);
  }

  button:active {
    transform: scale(1.1);
  }
}

.zoomImg {
  width: 24px;
  height: 24px;
  @include device(stdSmUp) {
    width: 32px;
    height: 32px;
  }
}
