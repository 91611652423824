@import 'src/styles/responsive';

.actionContainer {
  display: flex;
  gap: 4px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  @include device(stdSmUp) {
    justify-content: center;
  }
  @include device(stdLgUp) {
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
  }
}

.headSelect {
  width: 76px;
  :global {
    .ant-select-selector {
      height: 26px !important;
    }
  }
}

.headContentSelect {
  display: flex;
  align-items: center;
  column-gap: 8px;
}

.headInfo {
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  color: var(--dark-04);
}
.rowContainer {
  gap: 8px;
  margin-top: -32px;
  @include device(stdSmUp) {
    margin: 0;
    gap: 24px;
  }
}

.headerContainer {
  display: flex;

  gap: 16px;
  justify-content: center;
  flex-direction: column-reverse;
  align-items: center;

  @include device(stdXlUp) {
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
  }
}
