@import 'src/styles/responsive';

.newPatient {
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--light-02);
  border: 1px solid var(--light-03);
  cursor: pointer;
  width: fit-content;
  height: 44px;
  padding: 13px 15px;
  border-radius: 12px;
  gap: 16px;

  &:disabled {
    cursor: not-allowed;
    opacity: 0.8;
  }

  &Label {
    font-weight: 600;
    line-height: 24px;
    color: var(--dark-06);
    font-size: 16px;
  }
}

.addPatientIcon {
  width: 24px;
  height: 24px;
}
