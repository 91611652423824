.patient-selection-container {
  width: min(75vw, 500px);
  display: grid;
  row-gap: 24px;
  overflow-y: auto;
  max-height: 568px;
  padding: 0 10px;

  #action-button {
    width: 100% !important;
    border-radius: 18px !important;

    &:disabled {
      background-color: var(--primary-60) !important;
      color: white !important;
    }

    &[data-selected='true'] {
      background-color: var(--primary-100) !important;
    }
  }

  .info-select {
    color: var(--dark-04);
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: center;
    margin: 0;
  }

  .item {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 16px 32px;
    gap: 16px;
    border-radius: 18px;
    border: 1px solid var(--light-03);

    .interval {
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px;
      margin: 0;
      width: fit-content;
    }
  }

  .ant-checkbox-wrapper {
    width: min-content;

    .ant-checkbox {
      top: 0 !important;
    }

    span {
      margin: 0;
      color: var(--tertiary-80);
      font-size: 24px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px;
      user-select: none;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}
